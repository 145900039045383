
































































import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

interface RecommendedDimensionObject {
  category: object;
  item: object;
  slider: object;
}

const ConceptImageSizesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {},
  mixins: [translations],
})
export default class ConceptImageSizes extends ConceptImageSizesProps {
  translate!: Function;
  recommendedDimensions: RecommendedDimensionObject = {
    item: {
      height: "",
      width: "",
    },
    category: {
      height: "",
      width: "",
    },
    slider: {
      height: "",
      width: "",
    },
  };

  created() {
    if (this.data) {
      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["category"]
      ) {
        this.recommendedDimensions.category = this.data[
          "recommended-dimensions"
        ]["category"];
      }

      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["item"]
      ) {
        this.recommendedDimensions.item = this.data["recommended-dimensions"][
          "item"
        ];
      }

      if (
        this.data["recommended-dimensions"] &&
        this.data["recommended-dimensions"]["slider"]
      ) {
        console.log(this.data["recommended-dimensions"]["slider"]);
        this.recommendedDimensions.slider = this.data["recommended-dimensions"][
          "slider"
        ];
      }
    }
  }
}
